<template>
    <div class="">
            <div class="profile-body pt-3">
                <custom-alert v-if="displayAlert==true" :message="alertMessage"
                              v-on:showedAlert="resetAlert"></custom-alert>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card" v-if="profileData.info.personal.relation == 0&&!(subdomain=='waterski'&&[11,12].includes(profileData.info.membership.id))">
                            <div class="card-header row">
                                <div class="header-title col-sm-10">
                                    <h3>Auto Renewal</h3>
                                </div>
                                <div class="pull-right col-sm-2" style="margin-top: 8px;">
                                    <AutoRenewalButton :page="page" :profileData="profileData" :autoRenew="autoRenew" :autoRenewData="autoRenewData" @loadMembersInfo="loadMembersInfo" />
                                </div>
                            </div>
                            <div class="card-body" v-if="![7,9,10].includes(profileData.info.membership_status)">
                                <i class="fa fa-circle-notch fa-2x fa-spin" v-if="fetchCard"></i>
                                <div class="col-12" v-show="!fetchCard && CardInfo.cardNumber">
                                    <div class="row alert alert-secondary">
                                        <div class="col-4 justify-content-center align-self-center">
                                          <i class="fas fa-credit-card fa-lg mr-2"></i> {{ CardInfo.cardType }}
                                        </div>
                                        <div class="col-3 justify-content-center align-self-center"><p class="m-0 p-0">{{ CardInfo.cardNumber }}</p></div>
                                        <div class="col-5 text-right">
                                          <button class="btn btn-sm btn-primary mr-1" @click="$bvModal.show('autoRenewalModal');"><i class="fa fa-edit mr-2"></i>Change</button>
                                          <button class="btn btn-sm btn-danger" @click="removeCard"><i class="fa fa-trash mr-2"></i>Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-show="!fetchCard && !CardInfo.cardNumber">
                                    <div class="row alert alert-secondary">
                                        <span>You can add card by enabling auto renewal</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Reset Password</h3>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Existing Password<sup class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.old_password"
                                                   class="form-control">
                                          <span class="text-sm text-danger">{{ errors.old_password||'' }}</span>
                                        </div>

                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Change Password<sup
                                                class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.password"
                                                   class="form-control">
                                          <span class="text-sm text-danger">{{ errors.password||'' }}</span>
                                        </div>

                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Confirm Password<sup
                                                class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.password_confirmation"
                                                   class="form-control">
                                          <span class="text-sm text-danger" :key="renderkey">{{ errors.password_confirmation||'' }}</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-left topspace">                                          
                                            <button type="submit" @click.prevent="updatePassword"
                                                    class="btn btn-outline-primary savebtn next-step-btn">Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="col-md-6 mb-3 d-none">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Security Questions</h3>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-alert
                                    :show="dismissCountDown"
                                    dismissible
                                    variant="warning"
                                    @dismissed="dismissCountDown=0"
                                    @dismiss-count-down="countDownChanged"
                                >
                                    <p>Security Questions updated successfully</p>
                                    <b-progress
                                        variant="warning"
                                        :max="dismissSecs"
                                        :value="dismissCountDown"
                                        height="4px"
                                    ></b-progress>
                                </b-alert>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">What is your mother's maiden name?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.maiden_info" name="maiden_info"
                                                   class="form-control">
                                          <span class="text-sm text-danger" :key="renderkey">{{ errors.maiden_info||'' }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">What city where you born in?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.birth_info" name="birth_info"
                                                   class="form-control">
                                          <span class="text-sm text-danger" :key="renderkey">{{ errors.birth_info||'' }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">What is the name of your first pet?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.pet_info" name="pet_info"
                                                   class="form-control">
                                          <span class="text-sm text-danger" :key="renderkey">{{ errors.pet_info||'' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-left topspace">                                         
                                            <button type="submit" @click.prevent="updateMemberSettingsInfo"
                                                    class="btn btn-outline-primary savebtn next-step-btn">Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card" v-if="!(subdomain=='waterski'&&member_type_id == 7) && templateTypes.length">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Email Settings</h3>
                                </div>
                            </div>
                            <div class="card-body emailsettingsec">
                                <div class="row">
                                    <template  v-for="(templateType,index) in templateTypes" >
                                    <div class="col-md-6" :key="index">
                                        <p class="m-0">{{templateType.type}}</p>
                                    </div>
                                    <div class="col-md-6 pt-3" :key="index">
                                        <div class="d-flex"> 
                                            <label class="switch toggle ml-2">
                                                <input type="checkbox"
                                                       v-model="CheckedValue[templateType.id]" :value="templateType" name="isAddress[]" :id="templateType.type"  >
                                                <span class="slider round"></span>
                                                <span class="labels"></span>
                                            </label>
                                        </div>
                                    </div>
                                    </template>
                                   
                                    
                                   
                                   
                                   
                               
                                </div>
                                <div class="row">
                                <div class="col-md-12">
                                    <div class="text-left topspace mt-4">                                         
                                        <button type="submit"
                                                class="btn btn-outline-primary savebtn next-step-btn" @click="submitSubscribe()">Save
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <!-- <div class="dashboard-qustion ">
                         <p class="footer-quest pb-3" v-html="this.getSiteProps('footertop.question')"></p>
                </div> -->
            </div>
            <!-- profile body end-->
    </div>
</template>

<script>
import axios from 'axios';
import CustomAlert from "../../../CustomAlert.vue";
import AutoRenewalButton from "../../../admin/components/AutoRenewalButton";

export default {
    props: {
        id: {required: true},
    },
    name: "myProfileSettings",
    components: {AutoRenewalButton, CustomAlert},
    data(){
        return {
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
             stripeToken: null,
            page:'setting',
            stripeValidate: false,
            renderkey:0,
            profileData: {
                info: [],
            },
            UpdateProfile:{
                "maiden_info": "",
                "pet_info": "",
                "birth_info": ""
            },
            ResetPassword:{
                old_password:"",
                password:"",
                password_confirmation:"",
            },
          errors:{},
            CardInfo:{
                stripeToken: "",
                cardNumber: "Loading...",
                expirationDate: "",
                cardType: "..."
            },
            fetchCard: false,
            notificationData: {
                do_not_release_email:"",
                do_not_email:"",
                do_not_call:"",
                do_not_mail:"",
                magazine_mail:""
            },
            alertMessage: "",
            displayAlert: "",
            autoRenew:0,
            autoRenewData:{
                regId:"",
                status:"",
            },
            cardError: {},
            emailSubscribe:0,
            templateTypes:"",
            emailSettings:[],
            arr:[],
            arr1:[],
            select:true,
            CheckedValue:[],
            unsubform:[],
            memberId:0,
            member_type_id:''
        }
    },
    methods:{
        loadMembersInfo(){
            axios.get(this.basePath+"api/member/my_profile",{ headers:this.memberHeaders })
                .then((response)=>{
                    this.profileData.info  = response.data.data;
                    const resp  = response.data.data.security_questions;
                    this.autoRenew = this.profileData.info.auto_renew;
                    this.autoRenewData.regId = this.profileData.info.registration[0].id;
                    this.memberId = this.profileData.info.registration[0].member_id;
                    this.member_type_id = this.profileData.info.membership.member_type_id;
                    this.autoRenewData.status = this.profileData.info.auto_renew;
                    for (let prop in resp) {
                        if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                            this.UpdateProfile[prop] = resp[prop]
                        }
                    }
                    this.getCardInformation()
                  this.templateTypeList();
                }).catch((error) =>{
                console.log(error);
            });
        },
        getCardInformation(){
          this.fetchCard = true;
            axios.get(this.basePath+"api/member/creditcard_info/"+this.profileData.info.registration[0].id,{ headers:this.memberHeaders })
                .then((response)=>{
                  this.fetchCard = false;
                    if(response.data.status != 'error'){
                        this.CardInfo = response.data.data;
                        this.UpdCardInfo.cardNumber = this.CardInfo.cardNumber;
                        this.UpdCardInfo.cvv = this.CardInfo.cvv;
                    }
                    else{
                        this.autoRenew = 0;
                        this.autoRenewData.status = 0;
                        this.CardInfo.cvv = '';
                        this.CardInfo.cardNumber = false;
                    }
                }).catch((error) =>{
              this.fetchCard = false;
                console.log(error);
            });
        },
        updateMemberSettingsInfo(){
          if(this.questionValidate())
          {
            axios.post(this.basePath+'api/member/sec_settings', this.UpdateProfile,{ headers:this.memberHeaders })
                .then(function (response) {
                    this.loadMembersInfo();
                    this.showSuccessAlert();
                    console.log(response);
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
          }
        },
      questionValidate()
      {
        let val = true;
        this.errors.pet_info="";
        this.errors.birth_info="";
        this.errors.maiden_info="";
        if(this.UpdateProfile.pet_info==''||this.UpdateProfile.pet_info==null)
        {
          val = false;
          this.errors.pet_info="Pet name is required";
        }
        if(this.UpdateProfile.birth_info==''||this.UpdateProfile.birth_info==null)
        {
          val = false;
          this.errors.birth_info="Birth city is required";
        }
        if(this.UpdateProfile.maiden_info==''||this.UpdateProfile.maiden_info==null)
        {
          val = false;
          this.errors.maiden_info="Mother's maiden name is required";
        }
        this.renderkey++;
        return val;
      },
      validate(){
          let val = true;
          this.errors.old_password="";
          this.errors.password="";
          this.errors.password_confirmation="";
          if(this.ResetPassword.old_password=='')
          {
            val = false;
            this.errors.old_password="Old password is required";
          }
          if(this.ResetPassword.password=='')
          {
            val = false;
            this.errors.password="Password is required";
          }
          if(this.ResetPassword.password_confirmation=='')
          {
            val = false;
            this.errors.password_confirmation="Confirm password is required";
          }
        if(this.ResetPassword.password != this.ResetPassword.password_confirmation)
        {
          val = false;
          this.errors.password_confirmation="Password and Confirm password should be same";
        }
        this.renderkey++;
        return val;
      },
        updatePassword(){
          if(this.validate())
          {
            axios.post(this.basePath+'api/member/change-password', this.ResetPassword,{ headers:this.memberHeaders })
                .then(function (response) {
                  if(response.data.status=="error"){
                    this.errors.password_confirmation=response.data.message;
                    this.renderkey++;
                  }
                  else{
                    this.alertMessage = "Password Updated Successsfully.";
                    this.displayAlert = true;
                    this.ResetPassword.old_password='';
                    this.ResetPassword.password='';
                    this.ResetPassword.password_confirmation='';
                  }
                }.bind(this))
                .catch(function (error) {
                  console.log(error);
                });
          }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showSuccessAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        updateNotificationInfo(){
            axios.post(this.basePath+'api/member/save_notification', this.notificationData,{ headers:this.memberHeaders })
                .then(function (response) {
                    this.loadNotificationInfo();
                    this.alertMessage = "Notification saved successfully";
                    this.displayAlert = true;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        loadNotificationInfo(){
            axios.get(this.basePath+"api/member/get_notification",{ headers:this.memberHeaders })
                .then((response)=>{
                    const resp  = response.data.data;
                    for (let prop in resp) {
                        if ( Object.prototype.hasOwnProperty.call(this.notificationData, prop) ) {
                            this.notificationData[prop] = resp[prop]
                        }
                    }
                }).catch((error) =>{
                console.log(error);
            });
        },
        getUnsubscribe(){
            this.memberInfoData = JSON.parse(localStorage.getItem('member'));
            this.header =  Object.assign({},  this.memberHeaders, {"application-id": process.env.VUE_APP_APPLICATION_ID});
            axios.post(this.basePath+'api/getUnsubscribeMembers',{member_id:this.memberId}, { headers:this.memberHeaders })
            .then((response)=>{
              var resp = response.data.unsubscribe;
              let unsubscriber = resp
              const checkedData  = [];
              for (let prop in this.templateTypes) {
                if(!unsubscriber.includes(this.templateTypes[prop].id)){
                  checkedData[ this.templateTypes[prop].id] = true;
                }else{
                  checkedData[ this.templateTypes[prop].id] = false;
                }
              }
              this.CheckedValue = Object.assign({},  checkedData);

                })
                .catch((error)=>{
                    console.log(error);
                });
        },
        compareData(response){
                   console.log('unsubscribe member',response.data);
                   console.log('template type',this.templateTypes);
        },
        templateTypeList() {
            this.memberInfoData = JSON.parse(localStorage.getItem('member'));
                axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/getTemplateType',
                    this.memberInfoData.member_id, {
                        headers: {
                        "application-id": process.env.VUE_APP_APPLICATION_ID,
                        'Content-Type': 'application/json'
                        }
                    }).then(response => {

                    if (response.data.status == 'Success') {
                        this.getUnsubscribe();

                        this.templateTypes = response.data.templateType;
                      const checkedData  = [];
                      for (let prop in response.data.templateType) {
                          checkedData[ this.templateTypes[prop].id] = true
                      }

                        this.CheckedValue = Object.assign({},  checkedData);
                    console.log(this.templateType);
                    } else {
                    console.log(response);
                    }
                }).catch(error => {
                    this.errors = error.response.data.errors || {};

            });
        },

        submitSubscribe(){
          this.unsubform = [];
          for(let chkval in this.CheckedValue)
          {
            if(!this.CheckedValue[chkval]){
              this.unsubform.push(chkval);
            }
          }
          if(this.unsubform){
            let formData = Object.assign({},  {'template_type_id': this.unsubform});
            axios.post(this.basePath+'api/unsubscribeSave/'+this.memberId,formData, { headers:this.memberHeaders })
            .then((response)=>{
                  this.alertMessage = "Email Settings changed successfully";
                  this.displayAlert = true;  console.log('unsubscribe member',response.data)
                })
                .catch((error)=>{
                });
          }
        },
        resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
      },
      removeCard(){
          const messageVNode1 = this.$createElement('h6', { class: 'mb-2' }, 'Are you sure you want to remove the card?');
          const messageVNode2 = this.$createElement('small', { class: 'text-danger' }, '*Auto-renewal will also be turned off automatically');
          this.$bvModal.msgBoxConfirm([messageVNode1,messageVNode2], {
            size: 'md',
            buttonSize: "sm",
            okVariant: "danger",
            okTitleHtml: "<i class='fa fa-trash mr-2'></i> Yes, Remove It",
            cancelTitle: 'No, Leave it',
            footerClass: 'p-2',
            hideHeader: true,
            hideHeaderClose: false
          })
          .then(value => {
            if (String(value) === "true") {
              this.fetchCard = true;
              this.autoRenewData.status = 0;
              axios.post(this.basePath + 'api/member/auto-renew-status', this.autoRenewData,{ headers:this.memberHeaders })
              .then(function (response) {
                this.loadMembersInfo();
              }.bind(this))
              .catch(function (error) {
                console.log(error);
              });
            }
          });
      }
    },
    mounted() {
        this.loadMembersInfo();
        this.loadNotificationInfo();
       // this.templateTypeList();
        //this.getUnsubscribe();
        
    },
    computed : {
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: 12}, (value, index) => year + index)
        }
    }
}

</script>
<style>
/*new Toggle Switch style*/
      .toggle {
        --width: 38px;
        --height: calc(var(--width) / 2.5);
        height: 18px !important;
        position: relative;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
        border-radius: var(--height);
        cursor: pointer;
    }

    .toggle input {
      display: none;
    }

    .toggle .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--height);
      background-color: #ccc;
      transition: all 0.4s ease-in-out;
    }

    .toggle .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
    transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider {
      background-color: #28a745;
    }

    .toggle input:checked+.slider::before {
      transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
      position: absolute;
      top: 7px;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-family: sans-serif;
      transition: all 0.4s ease-in-out;
    }

    .toggle .labels::after {
      content: attr(data-off);
      position: absolute;
      right: 10px;
      color: #4d4d4d;
      opacity: 1;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      transition: all 0.4s ease-in-out;
    }

    .toggle .labels::before {
      content: attr(data-on);
      position: absolute;
      left: 10px;
      color: #ffffff;
      opacity: 0;
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked~.labels::after {
      opacity: 0;
    }

    .toggle input:checked~.labels::before {
      opacity: 1;
    }
    .emailsettingsec p {
        font-weight: 600;
    }
    /*new Toggle Switch Style End*/

</style>